import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCoffee} from '@fortawesome/free-solid-svg-icons'
import {faUser} from '@fortawesome/free-solid-svg-icons'

import './App.css';
import TopMenu from './TopMenu'

import 'bootstrap/dist/css/bootstrap.min.css';;

function App() {
    return (
        <div className="App">
            <TopMenu/>

            <Container fluid>
                <section className="mt-5 pt-5 mb-5 pb-5">
                    <Container>
                        <Row>
                            <Col className="jumbotron col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <h1 className="display-4 fw-bolder">Easiest way to manage your taxis!</h1>
                                <Row>
                                    <Col>
                                        <p className="mt-5">
                                            <strong className="lead ">
                                                Access your data anywhere, anytime on any device!
                                            </strong>
                                        </p>
                                    </Col>
                                </Row>


                                <Row className="mt-5 pb-5">
                                    <Col className="mb-3 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                        <Button variant="success"
                                                href={process.env.REACT_APP_MTM_URL + "/register/"}>
                                            <strong>Get started for free</strong>
                                        </Button>
                                    </Col>
                                    <Col className="col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                                        <Button variant="primary" className="text-warning bg-dark"
                                                href={process.env.REACT_APP_MTM_URL + "/login/"}>
                                            <FontAwesomeIcon icon={faUser}/>
                                            &nbsp;&nbsp;
                                            <strong>Login to your account</strong>
                                        </Button>
                                    </Col>
                                </Row>

                            </Col>

                            <Col className="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Row>
                                    <Col>
                                        <div className="ratio ratio-16x9">
                                            <iframe src="https://www.youtube.com/embed/e8l9eBpcYf4"
                                                    title="YouTube video" allowFullScreen>
                                            </iframe>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>
        </div>
    );
}

export default App;
