import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export default class TopMenu extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <Navbar variant="dark" expand="lg" className="bg-mtm">
                <Container>
                    <Navbar.Brand href="#home">
                        <img
                            alt=""
                            src="/top-logo.svg"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                        />{' '}
                        MyTaxiManager
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <NavDropdown title="Features" id="basic-nav-dropdown" disabled>
                                <NavDropdown.Item href="#action/3.5">Overview</NavDropdown.Item>
                                <NavDropdown.Divider/>
                                <NavDropdown.Item href="#action/3.1">Rosters</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.2">Payin</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.3">Taxis</NavDropdown.Item>
                                <NavDropdown.Item href="#action/3.4">Driver App</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="#home" disabled>Pricing</Nav.Link>
                            <Nav.Link href="#link" disabled>Enterprise</Nav.Link>
                            <Nav.Link href="#link" disabled>Getting Started</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto">
                            <Nav.Link href={process.env.REACT_APP_MTM_URL + "/login/"} className="text-warning">
                                <FontAwesomeIcon icon={faUser}/>
                                &nbsp;
                                Login
                            </Nav.Link>
                            &nbsp;&nbsp;
                            <Nav.Item>
                                <Button href={process.env.REACT_APP_MTM_URL + "/register/"} variant="success">
                                    <strong>Sign up!</strong>
                                </Button>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}
